@import "../../assets/stylings/variables.css";

.btn {
  width: 100%;
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: var(--color-text--light);
  border: 0;
  padding: 11px;
  border-radius: 13px;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  text-align: center;
  max-height: 31px;
  justify-content: center;
  align-items: center;
}

.btn-secondary {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.btn-outlined {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.btn-outlinedLight {
  background-color: transparent;
  border: 2px solid var(--color-secondary);
  color: var(--color-primary);
}

.btn-text {
  background-color: transparent;
  text-decoration: underline;
  color: var(--color-primary);
}

.btn-small {
  width: 30vw;
  padding: 8px;
  margin-top: 20px;
  max-width: 150px;
  min-width: 50px;
}

.btn-medium {
  width: 60vw;
  max-height: 200px;
  height: 50px;
  font-size: 20px;
  min-width: 160px;
}

.btn-large {
  width: 65vw;
  max-width: 340px;
}

.btn-icon {
  margin: -3px 12px -3px 0;
}

.btn-circle {
  max-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 100px;

  font-size: 32px;
}

button:disabled,
button[disabled] {
  opacity: 33%;
  pointer-events: none;
}

button:active {
  opacity: 85%;
}

.btn-disabled {
  background-color: var(--color-disabled);
  border: 2px solid var(--color-secondary);
  color: var(--color-primary);
}
