@import "../../assets/stylings/variables.css";

.drawerContaier {
  background-color: transparent;

  border-radius: 17px 17px 0px 0px;
}

.drawerContent {
  height: 70vh;
  background-color: var(--color-background);
  color: var(--color-primary);
}
