@import "./assets/stylings/variables.css";

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btnMedium {
  color: #013a65;
}

.container {
  padding: 0;
  margin: 0;

  width: 100%;
}

.containerPicture {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;

  background-image: url("/src/assets/images/backGroundBottlesLarge.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: local;
  background-position: center;
}

@media (max-width: 590px) {
  .containerPicture {
    background-image: url("/src/assets/images/backGroundBottlesphone.png");
  }
}

.testContainer {
  justify-content: center;

  height: 90vh;
}

.outlinedText {
  -webkit-text-stroke: 1.1px #013a65;
}

.footer {
  position: relative;
  bottom: 0;
  align-items: center;
}

.coockie {
  position: fixed;
  bottom: 0;
  margin-bottom: 20px;
  align-items: center;
}

.logo {
  max-width: 250px;
  width: 200px;
  margin-left: 20px;
  min-width: 150px;
}

.box {
  --mask: radial-gradient(
        33.8px at 50% calc(100% + 28px),
        #0000 calc(99% - 5px),
        #000 calc(101% - 5px) 99%,
        #0000 101%
      )
      calc(50% - 28px) calc(50% - 9.5px + 0.5px) / 56px 19px repeat-x,
    radial-gradient(
        33.8px at 50% -28px,
        #0000 calc(99% - 5px),
        #000 calc(101% - 5px) 99%,
        #0000 101%
      )
      50% calc(50% + 9.5px) / 56px 19px repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.teknikker {
  position: fixed;
  bottom: 0;
  height: auto;
  width: 70%;
  max-height: 325px;
  max-width: 350px;
  align-items: flex-start;
  justify-content: flex-start;
}

.emailInputField {
  border: 1px solid var(--color-primary);
  border-radius: 19px;
  align-items: center;
  padding: 0 8px 0 8px;
  height: 50px;
  width: 36px;
  justify-content: space-between;
}

.pictureCarusell {
  border-radius: 31px;
  background-color: var(--color-carusell);
}

.PictureBody {
  background-color: #fff4d7;
  padding-bottom: 50px;
}

@media (min-width: 1200px) {
  .PictureBody {
    border-radius: 40px;
  }
}

.PrivacyListStyling {
  font-family: "Inter";
  font-weight: "normal";
  letter-spacing: "1%";
  line-height: 2;
  color: var(--color-primary);
}
