/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2021 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Indian Type Foundry
 * License URL: https://www.fontspring.com/licenses/indian-type-foundry/webfont
 *
 *
 */
@import "./assets/stylings/variables.css";

@font-face {
  font-family: "neurialgrotesk-extrabold";
  src: url("./assets/fonts/neurialgrotesk.woff") format("woff2"),
    url("./assets/fonts/neurialgrotesk.woff") format("woff");
  font-weight: medium;
  font-style: medium;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  font-family: "neurialgrotesk-extrabold";
}
