@import "../../assets/stylings/variables.css";

.input-text {
  margin: 0;
}

.input-text label {
  font-family: Inter;
  display: block;
  color: var(--color-primary);
  font-weight: 500;
  font-size: 12px;
}

.input-text input {
  padding: 10px 0px;
  padding-left: 10px;
  display: block;
  width: 100%;
  color: var(--color-primary);
  border-color: var(--color-primary);
  background-color: transparent;
  border-width: 0px;
  outline: none;
  border-radius: 10px;
  font-size: 15px;
}

.input-text ::placeholder {
  color: #adccd8;
  opacity: 1;
}
