:root {
  --color-primary: #013a65;
  --color-secondary: #e4edf0;
  --color-light-blue: #e4edf0;
  --color-text--light: #fef7e7;
  --color-error: #c73a3a;
  --color-disabled: #ede6d4;
  --color-circle: #b8d9eb;
  --color-carusell: #d7f0ff;
  --color-background: #fefaf1;
  --color-stepper-disabled: #b7c4ca;
}
