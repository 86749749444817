@import "../../assets/stylings/variables.css";

.Defaultline {
  background-color: transparent;
  width: 100%;
  height: 1px;
  border: 3px solid var(--color-primary);
  border-radius: 11px;
}

.Defaultline-disabled {
  border: 3px solid var(--color-secondary);
}

.Defaultline-halfstep {
  width: 10%;
  border: 3px solid var(--color-secondary);
}

.circle {
  border-radius: 100px;
  font-size: 14px;
  height: 22px;
  padding-top: 3px;
  width: 22px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  background-color: var(--color-primary);
  color: var(--color-text--light);
}

.circle-disabled {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.dotted-circle {
  border-radius: 100px;
  height: 10px;
  padding-top: 3px;
  width: 10px;
  align-items: center;
  justify-content: center;

  background-color: var(--color-stepper-disabled);
  color: var(--color-stepper-disabled);
}

.dotted-circle-disabled {
  background-color: var(--color-primary);
  color: var(--color-text--light);
}
